@import 'utils';

.wrapper {
  width: 100%;
  padding: rem-calc(16 24);

  outline: none;
  border: none;
  background-color: getColor($c-bg-transparent);
  color: getColor($c-text-secondary);

  text-align: start;

  cursor: pointer;

  transition: background-color $duration;

  &:hover {
    background-color: getColor($c-bg-neutral-tertiary);
  }

  &:focus {
    background-color: getColor($c-bg-neutral-tertiary);

    outline: 1px solid getColor($c-border);
    outline-offset: -1px;
  }

  border-bottom: 1px solid getColor($c-border);

  &.disabled {
    cursor: not-allowed;
  }
}
