@import 'utils';

.contentWrapper {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: rem-calc(4);
  word-break: break-word;
}

.confettiBackground {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.achievementIconWrapper {
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  min-width: 77px;
  width: 77px;
  height: 77px;

  @include breakpoint(small down) {
    min-width: 40px;
    width: 40px;
    height: 40px;
  }
}

.buttonWrapper {
  flex-shrink: 0;
  margin-top: rem-calc(8);
}

.heading {
  color: getColor($c-text-default);
}

.description {
  color: getColor($c-text-tertiary);
}
