@import "utils";

.modal {
  max-width: 900px;
  margin: 0;
}

.title {
  @include breakpoint(small down) {
    position: sticky;
    top: 0;
    width: 100%;
    margin-top: rem-calc(-16);
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.sidebar {
  padding: 0;
}

.closeButton {
  @include breakpoint(medium up) {
    display: none;
  }
}

.modalTitle {
  @include breakpoint(medium down) {
    display: none;
  }
}

.sidebarTitle {
  @include breakpoint(large up) {
    display: none;
  }

  @include breakpoint(medium up) {
    margin-bottom: rem-calc(24);
  }

  position: sticky;
  z-index: 8;
  top: 24px;
  padding: rem-calc(0 16);
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: rem-calc(-16);
  background-color: getColor($c-bg-neutral);

  &::before {
    content: '';
    position: absolute;
    top: -24px;
    width: 100%;
    height: 24px;
    background-color: getColor($c-bg-neutral);
  }
}

.checkoutContainer {
  padding: rem-calc(16);

  @include breakpoint(medium up) {
    padding: rem-calc(0 32 32);
  }
}

