@import 'utils';

.copyButtonWrapper {
  position: absolute;
  top: rem-calc(1);
  right: rem-calc(1);

  border-radius: rem-calc(4);

  opacity: 0;

  transition: opacity $duration;
}

.copyButton {
  border-radius: rem-calc(3);
}

.container {
  position: relative;

  &:hover .copyButtonWrapper {
    opacity: 1;
  }
}

.pre {
  scrollbar-width: thin;
  position: relative;
  min-height: rem-calc(36);
  tab-size: 2;

  & ::-webkit-scrollbar {
    -webkit-appearance: none;
  }

  & ::-webkit-scrollbar:horizontal {
    height: rem-calc(8);
  }

  & ::-webkit-scrollbar-thumb {
    border-radius: rem-calc(8);
    background-color: getColor($c-icon-secondary);
  }
}
