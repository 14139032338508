@import "utils";

.container {
  position: relative;
  display: inline-block;
  line-height: 1px;
}

.image {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: scale(0.5) translate(-100%, -100%);
}
