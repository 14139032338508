@import 'utils';

.wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
}

.usernameWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.userName {
  color: getColor($c-text-default);
}

.list {
  display: flex;
  flex-direction: column;
  gap: rem-calc(10);
  color: getColor($c-text-secondary);
}

.listItem {
  display: inline-flex;
  gap: rem-calc(4);
  align-items: center;

  @include icon-styles {
    flex-shrink: 0;
    color: getColor($c-icon-tertiary);
    width: rem-calc(16);
    height: rem-calc(16);
  }
}

.professionIcon {
  opacity: 0.4;

  @include dark-mode-styles {
    background-color: getColor($c-icon-tertiary);
  }

  border-radius: 4px;
}

.statusWrapper {
  margin-top: rem-calc(5);
}
