@import 'utils';

.wrapper {
  display: flex;
  align-items: center;
  gap: rem-calc(8);
}

.iconWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: 1px solid getColor($c-border);
  box-shadow:  0px 1.71429px 5.14286px 0px rgba(28, 45, 64, 0.06);
  color: getColor($c-icon-tertiary);
  width: 32px;
  height: 32px;

  @include icon-styles {
    width: 16px;
    height: 16px;
  }
}

.badge {
  color: getColor($c-text-default);
}
