@import "utils";

.container {
  position: relative;
  display: grid;
  grid-template-columns: repeat(2, 50%);
  gap: rem-calc(4px);
  margin-top: rem-calc(12);

  & > li:last-child:nth-child(odd) {
    grid-column: 1 / -1;
  }
}

.title {
  color: getColor($c-text-default);
}
