@import 'utils';

.input {
  flex-grow: 1;

  @include text-platform-main;

  color: getColor($c-text-default);
  background-color: transparent;

  border: none;
  outline: none;

  &::placeholder {
    color: getColor($c-text-tertiary);
  }
}
