@import 'utils';

.list {
  flex-grow: 1;
  min-height: 0;

  display: flex;
  flex-direction: column;

  overflow-y: auto;
}

.loaderWrapper {
  height: rem-calc(128);
  position: relative;
}

.noResultsBlock {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: rem-calc(4);

  padding: rem-calc(32 0);
}

.noResultsHeading {
  @include text-platform-h4;
}

.noResultsText {
  @include text-platform-secondary
}

.startTypingBlock {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: rem-calc(4);

  padding: rem-calc(24 0);

  @include text-platform-secondary;

  color: getColor($c-text-quaternary);
}
