@import "utils";

.divider {
  width: 100%;
  display: flex;
  align-items: center;
  color: getColor($c-text-secondary);

  &::before,
  &::after {
    content: '';
    height: 1px;
    flex-grow: 1;
    background-color: getColor($c-border);
  }

  &::before {
    margin-right: rem-calc(16);
  }

  &::after {
    margin-left: rem-calc(16);
  }

  &:empty {
    &::before,
    &::after {
      margin: 0;
    }
  }
}

.verticalDivider {
  width: 1px;
  height: rem-calc(32);
  background-color: getColor($c-border);
  margin: rem-calc(0 16);

  @include breakpoint(small down) {
    margin: rem-calc(0 8);
  }

}
