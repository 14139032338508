@import "utils";

.coverScreen {
  @include covers-parent;
}

.popUp {
  position: absolute;
  z-index: 20;
}

.appearFromBottom {
  animation: appear-from-bottom 0.2s ease-out forwards;
}

.appearFromTop {
  animation: appear-from-top 0.2s ease-out forwards;
}

@keyframes appear-from-top {
  from {
    transform: translateY(-8px);
  }

  to {
    transform: translateY(0);
  }
}

@keyframes appear-from-bottom {
  from {
    transform: translateY(8px);
  }

  to {
    transform: translateY(0);
  }
}
