@import "utils";

.icon {
  display: block;

  fill: transparent;
  stroke: getColor($c-icon);
  transition: fill $duration $easing;
}

.isActive {
  fill: getColor($c-icon);
}

.lightGray {
  stroke: getColor($c-icon-tertiary);

  &.isActive {
    fill: getColor($c-icon-tertiary);
  }

  &:hover {
    fill: getColor($c-icon-tertiary);
  }
}
