@import "utils";

.timeTrackerWrapper {
  display: flex;
  align-items: center;

  @include breakpoint(medium down) {
    display: none;
  }
}

.timeTrackerModal {
  width: 300px;
  position: fixed;
  top: var(--header-height);
  margin-top: rem-calc(8);
  transform: translateX(-25%);
  z-index: 1;
  background-color: getColor($c-bg-neutral);
  border-radius: 8px;
  border: 1px solid getColor($c-border);
  opacity: 0;
  visibility: hidden;
  transform: translateY(-10px);
  transition:
    transform $duration $easing,
    opacity $duration $easing,
    visibility 0s $duration;
}

.timeTrackerModalActive {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
  transition-delay: 0s;
  color: getColor($c-text-default);
}

.currentTimeBlock {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: rem-calc(16);
}

.largeTimer {
  width: 200px;
  font-weight: 700;
  font-size: 44px;
  line-height: 44px;
}

.timeStats {
  display: flex;
  justify-content: space-between;
  gap: rem-calc(8);
}

.statsTitle {
  color: getColor($c-text-tertiary);
}
