@import 'utils';

.wrapper {
  display: flex;
  position: relative;
  flex-direction: column;
}

.headerWrapper {
  position: relative;
  left: -32px;
  width: calc(100% + 64px);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: rem-calc(24 32);
  background-color: getColor($c-bg-neutral-tertiary);
}

.titleWrapper {
  display: flex;
  align-items: center;
  gap: rem-calc(4);
}

.title {
  color: getColor($c-text-default);
}

.titleCount {
  color: getColor($c-text-tertiary);
}

.achievementsList {
  margin-top: rem-calc(56);
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  row-gap: rem-calc(32);

  @include breakpoint(small down) {
    grid-template-columns: repeat(2, 1fr);
  }
}

.achievementWrapper {
  max-width: 110px;
  align-self: center;
  justify-self: center;
}

.closeButton {
  color: $c-gray-30;
  transition: color $duration $ease-out;

  &:hover {
    color: $c-gray-50;
  }
}
