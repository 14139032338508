@import "utils";

.day {
  position: relative;
  width: 28px;
  height: 28px;
  color: getColor($c-text-default);
  border-radius: 50%;

  &:hover {
    .iconWrapper {
      z-index: 0;
      transform: rotateY(180deg);
    }

    .data {
      transform: rotateY(0deg);
    }
  }
}

.data {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;

  font-size: rem-calc(12);
  background-color: inherit;
  border-radius: inherit;

  transition: transform $duration $easing;
}

.today:not(.active):not(.frozen) {
  background-color: getColor($c-bg-neutral-tertiary);

  .data {
    font-weight: $font-weight-bold;
  }
}

.active {
  .data,
  .iconWrapper {
    background-color: getColor($c-bg-danger-secondary);
  }

  .data {
    color: getColor($c-icon-danger);
    font-weight: $font-weight-bold;
  }

  &.today {
    position: relative;

    &::after {
      @include absolute-center-x;

      content: '';
      width: 3px;
      height: 3px;

      bottom: 1.5px;

      background-color: getColor($c-text-brand);
      border-radius: 50%;
    }
  }
}

.frozen {
  .data {
    color: getColor($c-text-info);
    font-weight: $font-weight-bold;
    background-color: getColor($c-bg-info-secondary);
  }

  .iconWrapper {
    padding-bottom: 1px;
    background-color: getColor($c-bg-info-secondary);
  }
}

.active,
.frozen {
  .data {
    transform: rotateY(180deg);
    backface-visibility: hidden;
  }
}

.iconWrapper {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;

  background-color: transparent;
  border-radius: inherit;

  backface-visibility: hidden;
  transition: transform $duration $easing;
}

.iconFire {
  width: 20px;
  height: 20px;
}

.iconFrozen {
  width: 22px;
  height: 22px;
}
