@import 'utils';

.selectOption {
  display: flex;
  align-items: center;
  gap: rem-calc(8);

  @include text-platform-secondary;

  color: getColor($c-text-secondary);
}

.icon {
  min-width: max-content;

  color: getColor($c-icon-tertiary);
}
