@import 'utils';

.form {
  max-width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
}

.buttonsWrapper {
  display: flex;
  gap: rem-calc(12);
}

.button {
  flex: 0 1 50%;
}
