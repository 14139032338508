@import "utils";

.buttonWrapper {
  display: flex;

  @include breakpoint(medium down) {
    display: none;
  }
}

.globalSearchButton {
  padding: rem-calc(4 12);
}

.tooltipMessage {
  padding: rem-calc(4 12);

  z-index: 2;

  top: rem-calc(42);

  @include breakpoint(medium down) {
    display: none;
  }
}
