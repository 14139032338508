@import "utils";

.illustration {
  --c-illustration-border: #{getColor($c-border)};
  --c-illustration-outline: #{getColor($c-border-transparent)};

  border-radius: rem-calc(10);
  background-color: var(--c-illustration-outline);
  overflow: hidden;
}

.illustrationWithoutBorder {
  outline: 2px solid var(--c-illustration-outline);
}

.illustrationWithDefaultBorder {
  --c-illustration-border: #{getColor($c-border)};

  outline: 1px solid var(--c-illustration-outline);
}

.active {
  --c-illustration-border: #{getColor($c-border-brand-bold)};
  --c-illustration-outline: #{getColor($c-border-brand-bold)};
}
