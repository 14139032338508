@import 'utils';

.categoriesSelector {
  display: flex;
  flex-direction: column;
  gap: rem-calc(12);

  padding: rem-calc(16 24);

  border-bottom: 1px solid getColor($c-border);
}

.categoriesList {
  display: flex;
  align-items: center;
  gap: rem-calc(8);
}

.heading {
  line-height: rem-calc(14);
  color: getColor($c-text-tertiary);
}
