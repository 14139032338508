@import "utils";

.loaderWrapper {
  position: relative;
}

.payWithCardBlock {
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  align-items: center;
  gap: rem-calc(12);

  width: 100%;
  margin: rem-calc(8 0);
  padding: rem-calc(8 0);

  color: getColor($c-text-tertiary);
}

.divider {
  border-bottom: 1px solid getColor($c-border);
  height: 1px;
}
