@import "utils";

.field {
  text-align: left;
}

.label {
  @include text-platform-h4;

  display: inline-block;
  margin-bottom: rem-calc(8);

  color: getColor($c-text-default);
}

.hint {
  display: inline;

  @include icon-styles {
    margin-bottom: rem-calc(-4);
    margin-left: rem-calc(8);
  }
}

.required {
  position: relative;

  &::after {
    display: block;
    width: rem-calc(4);
    height: rem-calc(4);

    position: absolute;
    top: calc(50% - 2px);
    left: calc(100% + 5px);

    border-radius: 50%;
    background-color: getColor($c-icon-danger);
    content: '';
  }
}


.error {
  @include text-platform-small;

  color: getColor($c-text-default);

  display: flex;
  align-items: center;

  @include icon-styles {
    margin-right: rem-calc(4);
    color: getColor($c-icon-danger);
  }
}
