@import "utils";

.content {
  max-height: 70vh;
  padding: rem-calc(16 16 0);
  overflow-y: auto;
  overscroll-behavior-y: contain;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: rem-calc(46);
  border-bottom: 1px solid $c-gray-15;
}

.resolveAllButton {
  max-width: 150px;
}

.title {
  color: $c-gray-90;
  font-weight: $font-weight_extra-bold;
}

.loadMessageTrigger {
  width: 100%;
  height: 1px;
}

.loader {
  position: relative;
  height: 50px;
}
