@import 'utils';

.achievementsWrapper {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
}

.titleWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.title {
  color: getColor($c-text-default);
  @extend %text-truncate;
}

.titleCount {
  color: getColor($c-text-quaternary);
}

.viewAllButton {
  color: $c-gray-40;
  max-width: 100px;
}

.achievementItem {
  &:nth-child(n+6) {
    display: none;
  }
}
