@import 'utils';

@mixin isActive {
  &.active {
    @content;
  }
}

.wrapper {
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  width: rem-calc(24);
  height: rem-calc(24);
  position: relative;

  .arrow {
    position: absolute;
  }

  .arrow_up {
    transform: rotateZ(180deg);
    opacity: 0;
    top: 12px;
  }

  .arrow_down {
    opacity: 1;
    top: 0;
  }

  @include isActive {
    .arrow_up {
      top: 0px;
      opacity: 1;
    }

    .arrow_down {
      opacity: 0;
      top: -12px;
    }
  }
}

.arrow {
  display: block;
  transition:
    opacity $duration $easing,
    top $duration $easing;

  :global(.icon) {
    display: block;
  }
}
