@import 'utils';

.wrapper {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 5;
  width: 100%;
  height: var(--header-height);
  padding-right: var(--scrollbar-width);
  background-color: getColor($c-bg-neutral);
  display: flex;
  align-items: center;
  &::after {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    height: rem-calc(1);
    content: '';
    background-color: getColor($c-border);
  }

  &.hideOnMobileScreen {
    @include breakpoint(medium down) {
      display: none;
    }
  }

  &.withMobileAppMiniBanner {
    top: var(--header-height);
  }
}

.list {
  display: flex;
  justify-content: space-between;
  align-items: center;

  @include breakpoint(medium down) {
    padding-right: 0;
  }
}

.buttonsWrapper {
  display: flex;
  align-items: center;
  margin-left: auto;
}

.toggleWrapper {
  @include breakpoint(medium up) {
    display: none;
  }

  display: flex;
  align-items: center;
}

.divider {
  margin: 0;
}

.hiddenOnMobile {
  @include breakpoint(small down) {
    visibility: hidden;
  }
}

.notificationsDivider {
  @include breakpoint(medium only) {
    opacity: 0;
    margin-right: 0;
  }
}
