@import "utils";

.photoIconWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: rem-calc(32);
  height: rem-calc(32);
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) translate(var(--x), var(--y));
  background-color: getColor($c-fill-secondary);
  border: 1px solid getColor($c-border);
  cursor: pointer;
  transition: background-color $duration $easing;

  &:hover {
    background-color: getColor($c-fill-secondary-hovered)
  }
}

.photoIcon {
  position: relative;
  width: rem-calc(16);
  height: rem-calc(16);
  transition: color $duration $easing;
  color: getColor($c-icon-secondary)
}

.fileInput {
  @include visually-hidden;
}

.loader {
  background-color: transparent;
}
