@import "utils";

.card {
  width: 100%;
  border-radius: rem-calc(12);
  padding: rem-calc(16);
  transition: background-color $duration $easing, border-color $duration $easing;
  border: 1px solid getColor($c-border);

  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: rem-calc(8);

  cursor: pointer;

  &:hover {
    border-color: getColor($c-border-hovered);
    background-color: getColor($c-fill-tertiary-hovered);
  }
}

.container {
  display: flex;
  align-items: center;
  gap: rem-calc(12);
}

.iconContainer {
  display: flex;
  align-items: center;

  border: 1px solid getColor($c-border);
  padding: rem-calc(12);
  background-color: getColor($c-bg-neutral-secondary);
}

.icon {
  height: rem-calc(24);
  width: rem-calc(24);
  color: getColor($c-icon);
}

.textContainer {
  display: flex;
  flex-direction: column;
}

.titleContainer {
  display: flex;
  align-items: center;
  gap: rem-calc(4);

  color: getColor($c-text-secondary);
}

.subtitle {
  color: getColor($c-text-tertiary);
}
