@import "utils";

.wrapper {
  position: relative;
  display: flex;
  width: 100%;
  padding: rem-calc(16 0 4);
}

.time {
  color: getColor($c-text-tertiary);
}

.content {
  width: 100%;
}

.unread::after {
  content: "";
  position: absolute;
  top: rem-calc(8);
  left: 0;
  width: rem-calc(6);
  height: rem-calc(6);
  border-radius: 50%;
  background-color: getColor($c-icon-brand);
}

.ratingButton {
  width: 100%;
  background-color: getColor($c-fill-secondary);
  border: 1px solid getColor($c-border);
}
