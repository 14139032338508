@import "utils";

.terms {
  @include text-platform-small;
  color: getColor($c-text-tertiary);
  text-align: center;

  margin-bottom: rem-calc(8);

  :global(.link) {
    text-decoration: underline;
  }
}
