@import "utils";

.notificationsBlock {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  @include breakpoint(small down) {
    display: none;
  }
}

.dropdownContent {
  @extend %box-shadow;

  position: absolute;

  top: calc(100% + 20px);
  left: auto;
  right: rem-calc(28);
  z-index: 1;

  display: flex;
  flex-direction: column;
  justify-content: center;

  background-color: getColor($c-bg-neutral);
  opacity: 0;

  visibility: hidden;
  transition:
    transform $duration $easing,
    opacity $duration $easing,
    visibility 0s $duration;

  width: rem-calc(332);
  border-radius: 8px;
  border: 1px solid getColor($c-border);
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05);

  transform: translateY(-10px);

  @include dropdown-opened {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
    transition-delay: 0s;
  }

  @include breakpoint(small down) {
    display: none;
  }
}

.divider {
  @include breakpoint(medium down) {
    display: none;
  }
}

.notificationsMobileBlock {
  @include breakpoint(medium up) {
    display: none;
  }
}
