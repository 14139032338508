@import "utils";

.iconWrapper {
  flex-shrink: 0;
  width: calc(var(--size) / 2);
  height: calc(var(--size) / 2);

  max-width: rem-calc(24);
  max-height: rem-calc(24);
  display: flex;

  border: 2px solid getColor($c-bg-neutral);
  border-radius: 50%;
  background-color: getColor($c-bg-neutral);
}

.icon {
  width: 100%;
  height: 100%;

  --c-badge-icon: #{getColor($c-icon-bold)};
  --c-badge-background: #{getColor($c-fill-brand-bold)};
}
