@import 'utils';

.list {
  display: flex;
  flex-direction: column;

  min-width: rem-calc(200);

  border: 1px solid getColor($c-border);
  border-radius: 8px;
  background-color: getColor($c-bg-neutral);

  box-shadow: 0px 4px 12px 0px rgba(28, 45, 64, 0.06);
}
