@import 'utils';

.wrapper {
  width: 100%;
  display: inline-flex;
  gap: rem-calc(4);
  align-items: center;
  word-break: break-word;
}

.statusCommentIcon {
  margin-top: rem-calc(4);
  margin-right: rem-calc(4);
}

.statusText {
  display: inline-flex;
  align-items: flex-start;
  color: getColor($c-text-secondary);

  @include icon-styles {
    flex-shrink: 0;
    width: rem-calc(16);
    height: rem-calc(16);
    color: getColor($c-icon-tertiary);
  }
}

.editIconWrapper {
  margin-left: auto;
  color: $c-gray-30;
  width: 32px;
  height: 32px;

  @include icon-styles {
    color: getColor($c-icon);
    width: 24px;
    height: 24px;
  }
}

.setStatusButton {
  width: 100%;
  display: flex;

  @include icon-styles {
    width: 24px;
    height: 24px;
  }
}
