@import "utils";

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex: 1;

  background-color: getColor($c-bg-neutral);
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  flex: 1;
  max-width: rem-calc(434);
  text-align: center;

  @include breakpoint(small down) {
    padding: rem-calc(16);
  }
}

.counterWrapper {
  position: relative;
  margin-bottom: rem-calc(16);
}

.counterOutline {
  @include circle(56);

  position: relative;
  z-index: 2;

  display: flex;
  justify-content: center;
  align-items: center;
  padding: rem-calc(8);

  border: rem-calc(6) solid getColor($c-bg-neutral);
  background-color: getColor($c-bg-inverse);
}

.counter {
  color: getColor($c-text-inverse);
  font-family: $f-manrope;
  font-size: rem-calc(30);
  font-weight: $font-weight_bold;
}

@for $i from 1 through 4 {
  .counter[data-length="#{$i}"] {
    font-size: rem-calc(calc(34px - (4px * $i)));
  }
}

.charmFlame {
  @include absolute-center-x;

  z-index: 1;
  top: rem-calc(-70);
}

.charmFlameImage {
  opacity: 0;
  transition: opacity $duration ease-in-out;
}

.charmFlameImageLoaded {
  opacity: 1;
}

.heading {
  @include text-platform-h1;
  margin-bottom: rem-calc(32);
}

.daysWrapper {
  display: flex;
  flex-direction: column;
  gap: rem-calc(16);
  padding-block: rem-calc(24 16);
  border: rem-calc(1) solid $c-semi-light-gray;
}

.daysContainer {
  display: flex;
  padding-inline: rem-calc(24);
  margin: 0 auto;
  gap: rem-calc(24);
  border-radius: rem-calc(16);
}

.divider {
  height: rem-calc(1);
  background-color: $c-semi-light-gray;
}

.daysText {
  @include text-platform-secondary;

  color: getColor($c-text-secondary);
}

.daysTextContainer {
  padding-inline: rem-calc(16);
}

.dayTextWrapper {
  padding: rem-calc(8);
}

.footer {
  width: 100%;
  padding: rem-calc(12);
  background-color: getColor($c-bg-neutral);
  border-top: 1px solid getColor($c-border);
}
