@import 'utils';

$blockquote-margin: rem-calc(20);

.chatMarkdown {
  @include text-platform-secondary;
  white-space: pre-wrap;

  color: getColor($c-text-secondary);

  & ul, ol  {
    white-space: normal;
    margin-top: -16px;
  }

  & p, ul, ol {
    margin-bottom: 0;
  }

  & div>pre>code {
    display: block;
  }

  & a {
    color: getColor($c-text-info);
    @include text-platform-secondary;
  }

  & blockquote {
    position: relative;
    padding: 0 1em;
    border-left: none;

    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: $blockquote-margin;
      height: calc(100% - $blockquote-margin * 2);
      width: 4px;
      background-color: getColor($c-border);
    }
  }

  &:first-child blockquote {
    // TODO: This is needed to overwrite margins of Editable component
    margin-top: -$blockquote-margin !important;
  }

  &:last-child blockquote {
    // TODO: This is needed to overwrite margins of Editable component
    margin-bottom: -$blockquote-margin !important;
  }

  .mention,
  .ownMention {
    padding: rem-calc(4);
    border-radius: 4px;
  }

  .mention {
    color: getColor($c-text-info);
    background-color: getColor($c-bg-info-secondary);
  }

  .ownMention {
    color: getColor($c-text-accent-purple);
    background-color: getColor($c-bg-accent-purple-secondary);
  }
}
