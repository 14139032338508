@import 'utils';

.avatarWrapper {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  width: var(--size);
  height: var(--size);

  max-width: 100%;
  max-height: 100%;

  border-radius: 50%;
  background-color: getColor($c-bg-neutral-tertiary);

  aspect-ratio: 1;
};

.avatarWrapperWithWhitePadding {
  padding: calc(var(--white-padding-size, 4) * 1px);
  background-color: getColor($c-fill);
}

.avatarWrapperWithOnlineIndicator {
  --element-size: calc(var(--online-indicator-size, 12) * 1px);
  --border-size: calc(var(--online-indicator-size, 12) * 1px / 4);

  &::after {
    content: '';

    width: var(--element-size);
    height: var(--element-size);
    box-sizing: border-box;

    position: absolute;
    right: calc(-1 * var(--element-size) / 3);
    top: calc(var(--element-size) / 3);

    background-color: getColor($c-fill-success-bold);
    border: var(--border-size) solid getColor($c-fill);
    border-radius: 50%;

    transform: translateX(-25%) translateY(25%);
  }
}

.interactiveAvatar {
  cursor: pointer;
}

.avatarWrapperWithBadge {
  border: 2px solid getCoreColor('current');
  padding: rem-calc(2);

  &.type-Admin {
    color: getColor($c-badge-admin);
  }

  &.type-Bro {
    color: getColor($c-badge-bro);
  }

  &.type-Pro {
    color: getColor($c-badge-pro);
  }

  &.type-Unlim {
    color: getColor($c-badge-unlim);
  }

  &.type-ProfessionFrontend {
    color: getColor($c-badge-profession-frontend);
  }

  &.type-ProfessionJava {
    color: getColor($c-badge-profession-java);
  }

  &.type-ProfessionDesign {
    color: getColor($c-badge-profession-design);
  }

  &.type-ProfessionQa {
    color: getColor($c-badge-profession-qa);
  }

  &.type-ProfessionPython {
    color: getColor($c-badge-profession-python);
  }

  &.type-ProfessionRecruitment {
    color: getColor($c-badge-profession-recruitment);
  }

  &.type-ProfessionFullstack {
    color: getColor($c-badge-profession-fullstack);
  }

  &.type-RoleExternalMentor {
    color: getColor($c-badge-role-external-mentor);
  }

  &.type-RoleSales {
    color: getColor($c-badge-role-sales);
  }

  &.type-RoleModerator {
    color: getColor($c-badge-role-moderator);
  }

  &.type-Streak {
    color: getColor($c-badge-streak);
  }

  &.type-Employed {
    color: getColor($c-badge-employed);
  }

  &.type-JobChange {
    color: getColor($c-icon-info);
  }
}

.avatarImage {
  border-radius: 50%;
}

.badge {
  position: absolute;
  z-index: 1;

  top: 50%;
  left: 50%;

  transform: translate(-50%, -50%) translate(var(--badge-x), var(--badge-y));
}
