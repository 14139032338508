@import "utils";

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex: 1;

  background-color: getColor($c-bg-neutral);
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  flex: 1;
  max-width: rem-calc(434);
  text-align: center;

  @include breakpoint(small down) {
    padding: rem-calc(16);
  }
}

.iconContainer {
  border-radius: rem-calc(16);
  border: rem-calc(1) solid getColor($c-border);

  background: var(--color-bg-neutral-secondary, #FAFBFD);
  padding: rem-calc(24 40);
  margin-bottom: rem-calc(32);
}

.textContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: rem-calc(8);
}

.heading {
  @include text-platform-h1;

  color: getColor($c-text-default);
}

.description {
  @include text-platform-main;

  color: getColor($c-text-secondary);
}

.footer {
  width: 100%;
  padding: rem-calc(12);
  background-color: getColor($c-bg-neutral);
  border-top: rem-calc(1) solid getColor($c-border);
}
