@import "utils";

.icon {
  color: getColor($c-icon-brand);
  @include square(rem-calc(28));
}

.background {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  @include circle(rem-calc(28));

  &--GoalEmoji {
    background-color: $c-light-purple;
  }

  &--FlagUK {
    background-color: $c-light-blue;
  }
}

.customImage {
  position: absolute;
  @include circle(rem-calc(20));
}
