@import "utils";

.block {
  display: flex;
  gap: rem-calc(8);
  align-items: center;
  border: 1px solid getColor($c-border);
  border-radius: rem-calc(8);
  padding: rem-calc(8);
}

.blockTitle {
  @include text-platform-h4;
  color: getColor($c-text-default);

  @include disabled {
    color: getColor($c-text-disabled);
  }

  max-width: 130px;
  @extend %text-truncate;
}

.description {
  @include text-platform-small;

  color: getColor($c-text-tertiary);

  @include disabled {
    color: getColor($c-text-disabled);
  }

  max-width: 130px;
  @extend %text-truncate;
}

.titleWrapper {
  display: flex;
  flex-direction: column;
}
