@import "utils";

.content {
  max-height: 70vh;
  padding: rem-calc(16 16 0);
  overflow-y: auto;
  overscroll-behavior-y: contain;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: rem-calc(46);
  border-bottom: 1px solid getColor($c-border);
}

.title {
  color: getColor($c-text-default);
  font-weight: $font-weight_extra-bold;
}
