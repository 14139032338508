@import 'utils';

.details .summary {
  display: flex;
  align-items: center;
}

.summary::marker {
  content: '';
}

.openIcon {
  display: none;
}

.details .summary::-webkit-details-marker {
  display: none;
}

.closeIcon {
  display: inline;
}

details[open] .openIcon {
  display: inline;
}

details[open] .closeIcon {
  display: none;
}
