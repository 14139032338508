@import 'utils';

.message {
  margin: rem-calc(10px 0);
  box-shadow: 0px 4px 12px rgba(28, 45, 64, 0.06);
  border: 1px solid getColor($c-border);
  border-radius: 16px;
  background-color: getColor($c-bg-neutral);
  padding: rem-calc(24);
  display: flex;
  align-items: center;
  gap: rem-calc(16);
  position: relative;
  overflow: hidden;

  &:global(.message-enter) {
    opacity: 0;
    transform: translateX(150px);
  }

  &:global(.message-enter-active) {
    opacity: 1;
    transform: translateX(0);
    transition-property: transform, opacity;
    transition-duration: $duration;
    transition-timing-function: $easing;
  }

  &:global(.message-exit) {
    transform: translateX(0);
    opacity: 1;
  }

  &:global(.message-exit-active) {
    transform: translateX(150px);
    opacity: 0;
    transition-property: transform, opacity;
    transition-duration: $duration;
    transition-timing-function: $easing;
  }
}

.closeButtonWrapper {
  min-width: 32px;
  margin-left: auto;
}

.closeButton {
  color: getColor($c-icon-tertiary);

  cursor: pointer;
}
