@import 'utils';

.wrapper {
  display: flex;
  align-items: center;
  gap: rem-calc(32);
}

.controlsSection {
  display: flex;
  align-items: center;
  gap: rem-calc(8);
}

.arrows {
  display: flex;
  align-items: center;
  gap: rem-calc(4);
}

.icon, .button {
  background-color: getColor($c-bg-neutral-tertiary);
  border-radius: 2px;

  @include text-platform-h4;

  color: getColor($c-icon);
}

.button {
  padding: rem-calc(4 8);
}
