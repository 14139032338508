@import "utils";

.info {
  position: absolute;
  right: calc(100% + #{rem-calc(16)});

  width: max-content;
  padding: rem-calc(8 16);

  background-color: getColor($c-bg-neutral-tertiary);
  border: 1px solid getColor($c-border);
  border-radius: 4px;

  visibility: hidden;
  opacity: 0;
  transition: visibility 0s $duration, opacity $duration $easing;
  z-index: 1;
}

.wrapper {
  display: flex;
  align-items: center;

  position: relative;

  @include breakpoint(small down) {
    margin-right: rem-calc(16);
  }

  &:hover .info {
    visibility: visible;
    opacity: 1;
  }
}

.divider {
  @include breakpoint(small down) {
    display: none;
  }
}

.userName {
  color: getColor($c-text-brand)
}
