@import 'utils';

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: rem-calc(4);

  overflow: hidden;
}

.header {
  display: grid;
  grid-template-columns: max-content max-content max-content auto;
  align-items: center;
  column-gap: rem-calc(4);

  width: 100%;
}

.icon {
  color: getColor($c-icon);
}

.senderName {
  @include text-platform-h4;

  color: getColor($c-text-default);
}

.chatTitle {
  @include text-platform-secondary;

  color: getColor($c-text-tertiary);
}

.sendingTime {
  justify-self: end;

  @include text-platform-small;

  color: getColor($c-text-tertiary);
}
