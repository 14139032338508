@import 'utils';

.messageWrapper {
  @include smoothScroll;

  display: flex;
  flex-direction: column-reverse;

  width: rem-calc(450);
  position: fixed;
  padding: rem-calc(0 10 0 20);
  z-index: 16;
  max-height: calc(100% - 60px);

  @include breakpoint(small down) {
    inset: auto 0 0 0;
    padding: rem-calc(0 20);
    width: 100%;
  }

  &.bottomRight,
  &.indentedBottomRight {
    bottom: 0;
    right: 0;
  }

  &.indentedBottomRight {
    margin-bottom: rem-calc(48);

    @include breakpoint(small down) {
      margin-bottom: 0;
    }
  }
}
