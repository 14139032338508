@import 'utils';

.linksWrapper {
  display: flex;
  gap: rem-calc(16);
}

.title {
  color: getColor($c-text-default);
}
