@import 'utils';

.bottomBar {
  display: flex;
  align-items: center;

  padding: rem-calc(10 24);

  background-color: getColor($c-bg-neutral);

  border-radius: rem-calc(0 0 16 16);
  border-top: 1px solid getColor($c-border);

  @include text-platform-secondary;

  color: getColor($c-text-tertiary);
}

.totalCountLine {
  display: flex;
  align-items: center;
  gap: rem-calc(4);
}

.totalCountNumber {
  font-weight: $font-weight_bold;
  color: getColor($c-text-default);
}

.descriptionWrapper {
  margin-left: auto;
}
