@import "utils";

.reportProblem {
  position: absolute;
  right: var(#{$platform-container-indent});
  bottom: rem-calc(8);
  background-color: rgba($color: getColor($c-fill-tertiary), $alpha: 0.8);
  opacity: 0.9;
  transition: background-color $duration $easing, opacity $duration $easing;
  outline: none;
  z-index: 1;

  &:hover {
    background-color: rgba($color: getColor($c-fill-tertiary-hovered), $alpha: 1.0);
    opacity: 1;
  }

  &.staticPositioned {
    position: static;
  }

  &.withoutBackground {
    background-color: initial;
  }
}

.regularButtonHover:hover {
  background-color: getColor($c-fill-secondary-hovered);
  border-color: getColor($c-border-hovered);
}

.buttonWrapper {
  display: flex;
  justify-content: flex-end;
}

.submitButton {
  min-width: rem-calc(160);

  @include breakpoint(small down) {
    width: 100%;
  }
}
