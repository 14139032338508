@import 'utils';

.titleWrapper {
  display: flex;
  align-items: center;
  gap: rem-calc(4);
}

.icon {
  color: getColor($c-icon);
}
