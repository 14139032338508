@import 'utils';

.wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  margin-bottom: rem-calc(24);

  text-align: initial;
}

.closeButton {
  position: absolute;
  color: $c-gray-30;
  transition: color $duration $ease-out;
  right: 0;
  top: 24px;

  &:hover {
    color: $c-gray-50;
  }
}
