@import "utils";

.toggleWrapper {
  @include breakpoint(medium up) {
    display: none;
  }

  display: flex;
  align-items: center;
}
