@import 'utils';

.container {
  position: relative;
}

.triggerElement {
  width: 100%;
  height: 1px;
}

.loader {
  height: rem-calc(40);
}
