@import "utils";

.icon {
  position: absolute;
  top: 10px;
  left: 12px;
  transition-property: opacity, transform, background-color, color;
  transition-duration: $duration;
  transition-timing-function: $easing;
  flex-shrink: 0;
  @include square(rem-calc(32));
  border-radius: 50%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.container {
  @extend %button-reset;

  flex-shrink: 0;
  height: var(#{$header-height});
  width: 56px;
  display: flex;
  position: relative;

  &::after {
    right: 0;
    top: 0;
    bottom: 0;
    width: 1px;
    position: absolute;
    content: '';
    transition: opacity $duration $easing;
    opacity: 0;
  }

  .toggleIcon {
    opacity: 0;
    transform: translateX(-100%) scale(0.5);
    color: getColor($c-icon);
  }

  .burgerIcon {
    transform-origin: left center;
    color: getColor($c-icon);
  }

  &:hover {
    .toggleIcon {
      background-color: getColor($c-bg-brand-secondary);
    }
  }

  &.containerActive {
    transform: none;

    &::after {
      opacity: 1;
    }

    .toggleIcon {
      opacity: 1;
      transform: none;
    }

    .burgerIcon {
      color: getColor($c-icon);
      opacity: 0;
      transform: translateX(100%) skewX(-40deg);
    }
  }

  &.containerHidden {
    display: none;
  }

  @include breakpoint(large) {
    display: none;
  }

  @include breakpoint(small down) {
    display: flex;
    flex-grow: 0;
    justify-content: space-between;
    padding: rem-calc(0 16);
    align-items: center;
    transform: none;
  }
}
