@import 'utils';

.title {
  @include text-platform-h4;

  color: getColor($c-text-default);

  p {
    white-space: nowrap;
  }
}
