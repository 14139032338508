@import "utils";

.wrapper {
  position: relative;

  display: flex;
  align-items: center;
  justify-content: space-between;


  @include breakpoint(medium down) {
    display: none;
  }
}

.dropdownContent {
  @extend %box-shadow;

  position: absolute;

  top: calc(100% + 16px);
  left: auto;
  right: rem-calc(8);
  z-index: 1;

  display: flex;
  flex-direction: column;
  justify-content: center;

  background-color: getColor($c-bg-neutral);
  opacity: 0;

  visibility: hidden;
  transition:
    transform $duration $easing,
    opacity $duration $easing,
    visibility 0s $duration;

  border: 1px solid getColor($c-border);
  border-radius: 8px;

  transform: translateY(-10px);

  &::before {
    position: absolute;
    top: rem-calc(-9);
    right: rem-calc(16);
    z-index: 2;

    display: block;
    width: rem-calc(16);
    height: rem-calc(16);

    background: getColor($c-bg-neutral);

    border-top: 1px solid getColor($c-border);
    border-left: 1px solid getColor($c-border);

    content: '';
    transform: rotate(45deg);
  }

  @include dropdown-opened {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
    transition-delay: 0s;
  }

  @include breakpoint(medium down) {
    right: 0;
  }

  @include breakpoint(medium) {
    width: rem-calc(256);
  }
}

.container {
  margin-right: rem-calc(16);

  @include breakpoint(xlarge down) {
    margin-right: rem-calc(20);
  }
}

.triggerButton {
  @extend %button-reset;
}
