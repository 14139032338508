@import "utils";

.container {
  display: flex;
  flex-direction: column;
  gap: rem-calc(4);
  color: getColor($c-text-default);

  :global(.is-accent) {
    color: getColor($c-text-brand);
  }
}
