@import "utils";

.wrapper {
  display: grid;
  grid-template-columns: minmax(32px, 1fr) minmax(32px, 1fr);
  gap: rem-calc(8);
}

.button {
  &:hover {
    @include icon-styles {
      fill: getColor($c-icon);
    }
  }
}

.largeIcon svg {
  height: 32px;
  width: 32px;
}

.smallIcon svg {
  height: 20px;
  width: 20px;
}

.lightGrayStyling {
  &:hover {
    @include icon-styles {
      fill: getColor($c-icon-tertiary);
    }
  }
}

.buttonWrapper {
  display: flex;
  align-items: center;
}

.ratingCounter {
  @include text-platform-secondary;
}
