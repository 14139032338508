@import "utils";

.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;

  width: rem-calc(28);
  height: rem-calc(28);
  border-radius: 50%;
}

.successBackground {
  background-color: getColor($c-bg-success-secondary);
}

.errorBackground {
  background-color: getColor($c-bg-danger-secondary);
}

.icon {
  width: rem-calc(16);
  height: rem-calc(16);
}

.success {
  color: getColor($c-icon-success);
}

.error {
  color: getColor($c-icon-danger);
}
