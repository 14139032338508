@import "utils";

.divider {
  width: 0;
  transition: width $duration $easing;
}

.container {
  overflow: hidden;
  pointer-events: auto;
  height: var(#{$header-height});
  position: relative;
  display: flex;
  align-items: center;
  background-color: getColor($c-bg-neutral);
  display: contents;

  &.containerActive {
    .divider {
      width: calc(var(#{$sidebar-width}) - 124px);
    }
  }

  @include breakpoint(large) {
    width: var(--sidebar-width);
    justify-content: center;
  }

  @include breakpoint(small down) {
    #{$header-height}: 60px;
    width: calc(100% - var(#{$header-height}));

    &.containerActive {
      .divider {
        width: calc(100% - 124px);
        max-width: calc(var(#{$sidebar-width}) - 124px);
      }
    }
  }
}

.sidebarLogoLink {
  display: block;
  margin: rem-calc(8);
  color: getColor($c-icon-brand);
}

.noMargin {
  margin: 0;
}

.logoIcon {
  @include square(var(--logo-size, rem-calc(44)));
}

.sidebarLogoMobile {
  @include breakpoint(small down) {
    display: none;
  }
}

.toggleWrapper {
  @include breakpoint(small down) {
    display: none;
  }
}
