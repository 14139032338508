@import "utils";

.wrapper {
  display: grid;
  position: relative;

  @include breakpoint(medium up) {
    grid-template-columns: repeat(12, minmax(0, 1fr));
    gap: rem-calc(32);
  }
}

.checkoutWrapper {
  @include breakpoint(medium up) {
    grid-column: 1 / 7;
    grid-row: 1 / 2;
  }
}

.fullWidth {
  grid-column: 1 / 13;
}

.descriptionWrapper {
  @include breakpoint(medium up) {
    grid-column: 7 / 13;
    grid-row: 1 / 2;

    height: fit-content;
  }

  @include breakpoint(small down) {
    margin-bottom: rem-calc(16);
  }
}

.inputField {
  border-radius: rem-calc(4);
  padding: rem-calc(12 16);
  background-color: getColor($c-fill-secondary);
}

.loaderWrapper {
  position: relative;
}

.errorBanner {
  margin-bottom: 12px;
}
