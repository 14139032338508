@import 'utils';

.wrapper {
  display: flex;
  flex-direction: column;
  gap: rem-calc(4);
}

.header {
  display: flex;
  align-items: center;
  gap: rem-calc(4);
}

.courseName {
  @include text-platform-secondary;
  @include elipsis;

  color: getColor($c-text-tertiary);
}

.icon {
  color: getColor($c-icon);
}

.iconLock {
  color: getColor($c-icon-disabled);
}
