@import 'utils';

.wrapper {
  display: flex;
  flex-direction: column;
  gap: rem-calc(8);
}

.title {
  color: getColor($c-text-default);
}

.progressEntityType {
  color: getColor($c-text-tertiary);
}

.progressEntityName {
  color: getColor($c-text-secondary);
}

.currentProgressItem {
  display: flex;
  align-items: center;
  gap: rem-calc(8);
}

.topicAndModuleInfo {
  display: inline-flex;
  flex-direction: column;
  gap: rem-calc(4);
}

.emojiWrapper {
  @include icon-styles {
    height: 16px;
    width: 16px;
  }

  flex-shrink: 0;
  width: 32px;
  height: 32px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: 1px solid getColor($c-border);
}
