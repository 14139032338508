@import 'utils';

$DEFAULT_CLOSE_DELAY: 5000ms;

.timerLine {
  position: absolute;
  height: 4px;
  width: 100%;
  bottom: 0;
  left: 0;

  background-color: var(--timer-line-color, getColor($c-icon-tertiary));

  animation-play-state: running;
  animation: closeTimer var(--close-delay, $DEFAULT_CLOSE_DELAY) linear forwards;
  transform-origin: left center;

  &.paused {
    animation-play-state: paused;
  }
}

@keyframes closeTimer {
  0% {
    transform: scaleX(1);
  }

  100% {
    transform: scaleX(0);
  }
}
