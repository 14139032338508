@import "utils";

.avatarWrapper {
  position: relative;

  @include breakpoint(small down) {
    text-align: center;
  }
}

.iconWrapper {
  position: relative;

  display: flex;
  gap: rem-calc(16);

  &__isLarge {
    gap: rem-calc(32);

    @include breakpoint(large down) {
      gap: 0;
      margin-bottom: rem-calc(20);
    }
  }
}
