@import "utils";

.wrapper {
  height: 100vh;
  position: relative;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: flex-end;
  flex-direction: column;
  overflow: hidden;

  background-color: getColor($c-bg-neutral);
}

.chubbsContainer {
  position: relative;
  width: 100%;
  height: 50%;
  min-height: 30%;
  margin-top: rem-calc(40);
  max-width: 450px;

  @include breakpoint(small down) {
    height: 55vw;
    padding-inline: rem-calc(16);
  }
}

.errorCode {
  color: getColor($c-text-tertiary);
}

.button {
  @include breakpoint(small down) {
    width: 100%;
  }
}

.headerText {
  color: getColor($c-text-default);

  @include breakpoint(small down) {
    @include text-landing-h4;
  }
}
