@import "utils";

.streaksCalendar {
  position: relative;
  width: 100%;
  transition: height $duration $easing;
  overflow: hidden;
  border: 1px solid getColor($c-border);
  border-radius: 8px;
}

.navigationContainer {
  position: absolute;
  left: rem-calc(12);
  right: rem-calc(12);
  top: rem-calc(12);

  display: flex;
  justify-content: space-between;
}

.calendar {
  width: 100%;

  :global(.DayPicker-wrapper) {
    padding: 0;
  }

  :global(.DayPicker-Month) {
    width: 100%;
    margin: rem-calc(18 12 4);
  }

  :global(.DayPicker-Weekday) {
    color: getColor($c-text-tertiary);
  }

  :global(.DayPicker-Caption) {
    margin-bottom: rem-calc(12);
  }

  :global(.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside)) {
    background-color: transparent;
  }

  :global(.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):hover) {
    background-color: transparent;
  }

  :global(.DayPicker-Day) {
    padding: 3px 6px;
  }

  :global(.DayPicker-Caption) div {
    text-align: center;
    color: getColor($c-text-default);

    @include text-platform-h3;
  }
}
