@import "utils";

.wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: rem-calc(16 0);
}

.iconWrapper {
  padding: rem-calc(40);
  margin-bottom: rem-calc(16);
  background-color: getColor($c-bg-accent-purple-secondary);
  border-radius: 50%;
}

.icon {
  width: rem-calc(48);
  height: rem-calc(48);
  color: getColor($c-icon-accent-purple);
}

.message {
  color: getColor($c-text-tertiary);
}
