@import "utils";

.item {
  margin: 0;
  padding: rem-calc(0 16);
  flex-grow: 1;

  box-sizing: border-box;

  transition: background-color $duration $easing;

  &:first-child {
    z-index: 3;
    border-top-left-radius: rem-calc(8);
    border-top-right-radius: rem-calc(8);
  }

  &:last-child {
    border-bottom-left-radius: rem-calc(8);
    border-bottom-right-radius: rem-calc(8);
  }

  &:not(:last-child, [data-qa="profile-dropdown-settings-item"]) {
    .itemWrapper {
      border-bottom: 1px solid getColor($c-border);
    }
  }

  &:hover {
    background-color: getColor($c-fill-hovered);

    .itemIconOnHover {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-shrink: 0;

      margin-left: auto;
    }

    @include breakpoint(medium) {
      .itemWrapper {
        border-bottom-color: transparent;
      }
    }
  }
}

.itemWrapper {
  @include text-platform-secondary;

  position: relative;
  display: flex;
  align-items: center;
  padding: rem-calc(12 0);

  white-space: nowrap;

  transition: border-bottom-color $duration $easing;

  &:global(.is-active) {
    .itemIcon {
      color: getColor($c-icon);

      @include breakpoint(medium down) {
        &::before {
          position: absolute;
          left: 0;
          width: rem-calc(4);
          height: rem-calc(32);

          background-color: getColor($c-fill-brand-bold);
          border-radius: 6px;
          content: '';
        }
      }
    }

    .itemText {
      font-weight: $font-weight_extra-bold;
    }
  }
}

.itemIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  padding: rem-calc(4);

  margin-right: rem-calc(4);

  color: getColor($c-icon);
  transition: color $duration $easing;
}

.itemText {
  @include elipsis();

  color: getColor($c-text-default);
  transition: color $duration $easing;

  &::before {
    @include covers-parent;
  }
}

.itemButton {
  width: 100%;
  @extend %button-reset;
}

.hiddenOnMobile {
  @include breakpoint(medium down) {
    display: none;
  }
}

.itemIconOnHover {
  display: none;
}

