@import 'utils';

.wrapper {
  position: relative;
  background-color: getColor($c-bg-neutral);
  display: flex;
  flex-direction: column;
  border-radius: rem-calc(12);
  border: 1px solid getColor($c-border);
}
