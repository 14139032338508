@import 'utils';

$animationDuration: $duration;

$bottomSidebarHeights: 10, 20, 30, 40, 50, 60, 70, 80, 90, 95, 100;

$padding: 32;

@keyframes slideInFromRight {
  0% {
    display: none;
    transform: translateX(100%);
    opacity: 0;
  }
  100% {
    display: block;
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideOutToRight {
  0% {
    display: block;
    transform: translateX(0);
    opacity: 1;
  }
  100% {
    display: none;
    transform: translateX(100%);
    opacity: 0;
  }
}

@keyframes slideInFromBottom {
  0% {
    display: none;
    transform: translateY(100%);
    opacity: 0;
  }
  100% {
    display: block;
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes slideOutToBottom {
  0% {
    display: block;
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    display: none;
    transform: translateY(100%);
    opacity: 0;
  }
}

@keyframes slideOutToBottomFromCurrentPosition {
  0% {
    display: block;
    opacity: 1;
  }
  100% {
    display: none;
    transform: translateY(100%);
    opacity: 0;
  }
}

@keyframes openOverlay {
  0% {
    opacity: 0;
    display: none;
  }
  100% {
    opacity: .5;
    display: block;
  }
}

@keyframes closeOverlay {
  0% {
    opacity: .5;
    display: block;
  }
  100% {
    opacity: 0;
    display: none;
  }
}

.sidebarWrapper {
  display: none;
  scroll-behavior: smooth;
  position: fixed;
  top: var(#{$header-height});
  bottom: 0;
  right: 0;
  width: 420px;
  z-index: 2;
  padding: rem-calc(0 $padding $padding);

  background-color: getColor($c-bg-neutral);
  @include breakpoint(medium up) {
    border-left: 1px solid getColor($c-border);
  }

  overflow: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;

  transform: translateX(100%);

  @include breakpoint(small down) {
    z-index: 7;
    width: 100%;
    padding: 0 16px 16px;
    transform: translateY(100%);
  }
}

.leftSidebarWrapper {
  animation-duration: $animationDuration;
  animation-timing-function: $easing;

  &.active {
    display: block;
    animation-name: slideInFromRight;
    transform: translateX(0);
  }

  &.closeModal {
    display: block;
    animation-name: slideOutToRight;
    transform: translateY(100%);
  }
}

.bottomSidebarWrapper {
  width: 100vw;
  padding: 0 16px 16px;
  transform: translateY(100%);
  transition: transform $animationDuration $ease-out;
  border-radius: 32px 32px 0 0;
  border-top: 1px solid getColor($c-border);

  height: 80dvh;
  position: fixed;
  top: 20vh;
  z-index: 100;

  &.active {
    display: block;
    animation: slideInFromBottom $animationDuration $easing;
    transform: translateY(0);
  }

  &.closeModal {
    display: block;
    animation-name: slideOutToBottom;
    animation-duration: $animationDuration;
    animation-timing-function: $easing;
    transform: translateY(100%);
  }

  &.closeModalWithDragging {
    display: block;
    animation-name: slideOutToBottomFromCurrentPosition;
    animation-duration: $animationDuration;
    animation-timing-function: $ease-out;
    transform: translateY(100%);
  }

  &.isScrollLocked {
    overflow-y: hidden;
  }
}

@each $height in $bottomSidebarHeights {
  .bottomSidebarHeight-#{$height} {
    height: #{$height}dvh;
    top: #{100 - $height}dvh;
  }
}

.bottomSidebarHeight-auto {
  height: initial;
  top: initial;
}

.sidebarOverlay {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;

  opacity: 0;
  background-color: getColor($c-bg-page-overlay);
  z-index: 2;

  &.overlayActive {
    animation-name: openOverlay;
    animation-duration: $animationDuration;
    animation-timing-function: $easing;

    opacity: .5;
    display: block;
    transition-delay: 0s;
  }

  &.closeOverlay {
    animation-name: closeOverlay;
    animation-duration: $animationDuration;
    animation-timing-function: $easing;
  }

  @include breakpoint(small down) {
    display: none;
  }
}

.sidebarOverlayBottom {
  z-index: 7;

  @include breakpoint(small down) {
    &.overlayActive {
      display: block;
    }
  }
}

.header {
  margin: rem-calc(0 (-$padding) $padding);
  padding: rem-calc(24 32);

  background-color: getColor($c-bg-neutral-secondary);
}

.iconColor {
  color: getColor($c-icon);
}
