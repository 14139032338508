@import 'utils';

.inputSection {
  display: flex;
  align-items: center;
  gap: rem-calc(16);

  height: rem-calc(64);
  padding: rem-calc(20 24);

  border-radius: rem-calc(16 16 0 0);
  background-color: getColor($c-bg-neutral-secondary);
}

.searchIcon {
  color: getColor($c-icon-tertiary);
}

.filtersSection {
  display: flex;
  align-items: center;
  gap: rem-calc(8);

  padding: rem-calc(12 24);

  border-bottom: 1px solid getColor($c-border);
}

.filterWrapper {
  width: 50%;
}
