@import "utils";

.modal {
  width: rem-calc(300);
  padding: rem-calc(16);
  border-radius: 16px;
  border: 1px solid getColor($c-border);
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: getColor($c-bg-neutral);

  position: absolute;
  top: calc(100% - 6px);
  left: auto;
  z-index: 1;


  @extend %box-shadow;

  opacity: 0;
  visibility: hidden;
  transform: translateY(-10px);
  transition:
  transform $duration $easing,
  opacity $duration $easing,
  visibility 0s $duration;

  @include dropdown-opened {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
    transition-delay: 0s;
  }
}

.tag {
  cursor: pointer;
  user-select: none;
  transition: background-color, $duration $easing;

  &:hover {
    background-color: getColor($c-fill-brand-hovered);
  }
}
