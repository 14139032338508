@import 'utils';

.wrapper {
  display: grid;
  row-gap: rem-calc(24);
  column-gap: rem-calc(48);
  grid-template-areas:
    "subscriptions benefits"
    "cta cta"
  ;

  @include breakpoint(large) {
    padding-top: 16px;
  }

  @include breakpoint(medium down) {
    grid-template-areas:
      "subscriptions"
      "cta"
      "benefits"
    ;
  }
}

.cta {
  grid-area: cta;
}

.subscriptionPlansList {
  display: grid;
  grid-area: subscriptions;
  gap: rem-calc(16);
  grid-template-columns: repeat(auto-fill, minmax(246px, 1fr));

  @include breakpoint(medium down) {
    grid-template-columns: minmax(246px, 480px);
    justify-content: center;
    grid-gap: rem-calc(16);
  }

  &--vertical {
    display: flex;
    flex-direction: column;
    gap: rem-calc(16);
  }
}

.subscriptionPlanBenefits {
  padding-top: rem-calc(6);
  grid-area: benefits;
  display: flex;
  flex-direction: column;
  gap: rem-calc(16);
  color: getColor($c-text-default);
}

.subscriptionPlanBenefit {
  display: inline-flex;
  align-items: center;
  gap: rem-calc(8);

  @include breakpoint(medium down) {
    align-items: flex-start;
  }
}

.benefitIconWrapper {
  flex-shrink: 0;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: getColor($c-bg-success-secondary);
  display: inline-flex;
  justify-content: center;
  align-items: center;

  @include icon-styles {
    color: getColor($c-icon-success);
    width: 16px;
    height: 16px;
  }

  &:global(#{$accent}) {
    background-color: unset;
  }
}
