@import 'utils';

.wrapper {
  background-color: getColor($c-bg-neutral-secondary);
  border-radius: 0 0 8px 8px;
}

.titleWrapper {
  display: flex;
  align-items: center;
  gap: rem-calc(8);
  color: getColor($c-text-secondary);
}

.iconWrapper {
  background-color: getColor($c-bg-page);
  border-radius: 50%;
  width: 24px;
  height: 24px;
  border: 1px solid getColor($c-border);
  display: flex;
  align-items: center;
  justify-content: center;
}
