@import "utils";

.container {
  display: flex;
  flex-direction: column;
  gap: rem-calc(16);
  height: fit-content;

  transition: height $duration $easing;
}
