@import "utils";

.container {
  display: flex;
  flex-direction: column;
  gap: rem-calc(16);
}

.statContainer {
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  align-items: center;

  padding: rem-calc(10);

  border: 1px solid getColor($c-border);
  border-radius: 8px;
}

.divider {
  margin-inline: rem-calc(8);
}

.statBlock {
  display: flex;
  align-items: center;
  gap: rem-calc(6);
}

.xpIcon {
  --icon-size: #{rem-calc(20)};
  color: getColor($c-icon-accent-purple);

  @include square(20);
}

.frozenFireIcon {
  width: rem-calc(20);
  height: rem-calc(20);
}

.statText {
  display: flex;
  flex-direction: column;
  gap: rem-calc(2);
  color: getColor($c-text-secondary);
}

.statHint {
  color: getColor($c-text-tertiary);
  font-weight: $font-weight_medium;
}
