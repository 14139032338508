@import 'utils';

$messageTypes: (
  error: (
    iconColor: getColor($c-icon-danger),
    iconBackgroundColor: getColor($c-bg-danger-secondary),
    timerLineColor: getColor($c-fill-danger-bold)
  ),
  warning: (
    iconColor: getColor($c-icon-warning),
    iconBackgroundColor: getColor($c-bg-warning-secondary),
    timerLineColor: getColor($c-fill-warning-bold)
  ),
  success: (
    iconColor: getColor($c-icon-success),
    iconBackgroundColor: getColor($c-bg-success-secondary),
    timerLineColor: getColor($c-fill-success-bold)
  ),
  info: (
    iconColor: getColor($c-icon-info),
    iconBackgroundColor: getColor($c-bg-info-secondary),
    timerLineColor: getColor($c-fill-info-bold)
  ),
  system: (
    iconColor: getColor($c-icon-tertiary),
    iconBackgroundColor: getColor($c-bg-neutral-tertiary),
    timerLineColor: getColor($c-icon-tertiary)
  )
);

@each $messageType, $messageTypeData in $messageTypes {
  .#{$messageType} {
    .iconWrapper {
      display: flex;
      align-items: center;
      justify-content: center;

      width: 40px;
      height: 40px;
      border-radius: 50%;
      background-color: map-get($messageTypeData, iconBackgroundColor);

      :global(.icon) {
        color: map-get($messageTypeData, iconColor);
      }
    }

    --timer-line-color: #{map-get($messageTypeData, timerLineColor)};
  }
}

.iconWrapper {
  margin-top: rem-calc(4);
  min-width: 40px;

  @include breakpoint(small down) {
    display: none;
  }
}

.contentWrapper {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: rem-calc(4);
  word-break: break-word;
}

.heading {
  color: getColor($c-text-default)
}

.description {
  color: getColor($c-text-tertiary)
}
