@import 'utils';

$firework-animation-duration: 2s;

@mixin firework-icon($top, $left: unset, $right: unset) {
  @include square(40);
  position: absolute;

  top: $top;
  left: $left;
  right: $right;
}

@keyframes firework {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(0.65);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes firework-backwards {
  0% {
    transform: scale(0.65);
    opacity: 0;
  }
  50% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(0.65);
    opacity: 0;
  }
}

%firework {
  animation: firework $firework-animation-duration ease-in-out infinite;
}

%firework-backwards {
  animation: firework-backwards $firework-animation-duration ease-in-out infinite;
}

.wrapper {
  height: 100%;
  width: 100%;

  position: relative;
  background-color: $c-transparent;
}

.firework1 {
  @include firework-icon($top: 10%, $left: 20%);
  @extend %firework;
}

.firework2 {
  @include firework-icon($top: 30%, $left: 10%);
  @extend %firework-backwards;
}

.firework3 {
  @include firework-icon($top: 60%, $left: 25%);
  @extend %firework-backwards;

  @include breakpoint(small down) {
    display: none;
  }
}

.firework4 {
  @include firework-icon($top: 70%, $left: 5%);
  @extend %firework;
}

.firework5 {
  @include firework-icon($top: 5%, $right: 8%);
  @extend %firework;

  @include breakpoint(small down) {
    display: none;
  }
}

.firework6 {
  @include firework-icon($top: 20%, $right: 20%);
  @extend %firework-backwards;
}

.firework7 {
  @include firework-icon($top: 50%, $right: 25%);
  @extend %firework;
}

.firework8 {
  @include firework-icon($top: 80%, $right: 5%);
  @extend %firework-backwards;
}

