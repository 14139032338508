@import 'utils';

.wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: rem-calc(12 16);
}

.title {
  color: getColor($c-text-default);
}

.button {
  flex-shrink: 0;
}
