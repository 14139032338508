@import "utils";

.wrapper {
  padding: rem-calc(16);
  display: flex;
  flex-direction: column;
  gap: rem-calc(16);

  border-bottom: 1px solid getColor($c-border);
  border-top: 1px solid getColor($c-border);
}

.textItem {
  @include elipsis;
}

.tagIcon {
  width: rem-calc(16);
  height: rem-calc(16);
  margin-right: rem-calc(4);
}

