@import "utils";

.alert {
  position: relative;
  overflow: hidden;
  border-radius: 4px;
  color: getColor($c-text-default);
  padding: rem-calc(16 16 16 20);
  display: flex;
  align-items: center;
  gap: rem-calc(8);
  width: 100%;

  &::after {
    content: "";
    position: absolute;
    height: 100%;
    width: 4px;
    bottom: 0;
    left: 0;
  }

  @include icon-styles {
    display: block;
  }

  &:global(.error) {
    background-color: getColor($c-bg-danger);

    .iconWrapper {
      color: getColor($c-icon-danger);
    }

    &::after {
      background-color: getColor($c-fill-danger-bold);
    }
  }

  &:global(.success) {
    background-color: getColor($c-bg-success);

    .iconWrapper {
      color: getColor($c-icon-success);
    }

    &::after {
      background-color: getColor($c-fill-success-bold);
    }
  }

  &:global(.warning) {
    background-color: getColor($c-bg-warning);

    .iconWrapper {
      color: getColor($c-icon-warning);
    }

    &::after {
      background-color: getColor($c-fill-warning-bold);
    }
  }

  &:global(.info) {
    background-color: getColor($c-bg-info);

    .iconWrapper {
      color: getColor($c-icon-info);
    }

    &::after {
      background-color: getColor($c-fill-info-bold);
    }
  }

  &:global(.system) {
    background-color: getColor($c-bg-neutral-tertiary);

    .iconWrapper {
      color: getColor($c-icon-tertiary);
    }

    &::after {
      background-color: getColor($c-icon-tertiary);
    }
  }
}

.contentWrapper {
  flex-grow: 1;
  @include text-platform-secondary;
}

.closeButton {
  width: rem-calc(24);
  height: rem-calc(24);
  padding: 0;
  flex-shrink: 0;
}
