@import "utils";

.infoWrapper {
  padding: rem-calc(16);
  display: flex;
  flex-direction: column;
  gap: rem-calc(8);

  border-bottom: 1px solid getColor($c-border);
}

.textWrapper {
  display: flex;
  flex-direction: column;
  gap: rem-calc(4);
}

.textItem {
  @include elipsis;
}

.tagIcon {
  width: rem-calc(16);
  height: rem-calc(16);
  margin-right: rem-calc(4);
}

