@import "utils";

.button {
  position: relative;
  padding: rem-calc(4);
  overflow: visible;

  svg {
    margin: 0;
  }

  @include breakpoint(small down) {
    z-index: 1;
  }
}

.pageOpenedState {
  background-color: getColor($c-bg-neutral-tertiary);
  border-color: getColor($c-border-hovered);
  padding: rem-calc(4);
  margin: rem-calc(0 12);
  min-height: 0;

  &:disabled {
    opacity: 1;
  }
}

.unreadCountBlock {
  position: absolute;
  bottom: -2px;
  right: -2px;

  display: flex;
  align-items: center;
  justify-content: center;
  width: rem-calc(16);
  height: rem-calc(16);

  background-color: getColor($c-fill-brand-bold);
  color: $c-white;
  border-radius: 50%;
  border: 0.8px solid $c-white;

  z-index: 1;
}

.buttonDisabledCounter {
  @include breakpoint(small down) {
    right: -2px;
    bottom: -2px;
  }
}

.unreadCountText {
  font-size: rem-calc(8);
}
