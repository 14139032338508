@import 'utils';

.subscriptionPlanWrapper {
  position: relative;
  flex: 1;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: rem-calc(16);
  gap: rem-calc(12);
  border: 1px solid getColor($c-border);
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.04);
  background-color: getColor($c-bg-neutral);
  outline-width: 4px;
  outline-style: solid;
  outline-color: getColor($c-fill-transparent);
  outline-offset: 3px;
  transition: outline-color $duration $ease-in;
  cursor: pointer;

  &:global(#{$accent}) {
    border: 1px solid getColor($c-bg-pricing-card);
    background-color: getColor($c-bg-pricing-card);

    @include breakpoint(medium down) {
      margin-top: rem-calc(16);
    }
  }

  &:global(#{$active}) {
    outline-color: getColor($c-border-success-bold);
  }

  @include breakpoint(medium down) {
    width: 100%;
  }

  @include breakpoint(small down) {
    gap: rem-calc(6);
  }
}

.inputRadioWrapper {
  flex-grow: 0;
}

.badge {
  color: getColor($c-text-bold);
  position: absolute;
  top: 0;
  right: 16px;
  transform: translateY(-50%);
  padding: rem-calc(2 4);
  border-radius: 6px;
  background-color: getColor($c-fill-accent-purple-bold);
  border: 2px solid getColor($c-border-accent-purple-bold);
  display: flex;
  gap: rem-calc(2);
  align-items: center;

  @include icon-styles {
    color: getColor($c-icon-bold);
    width: 16px;
    height: 16px;
  }
}

.titleContainer {
  display: flex;
  gap: rem-calc(12);
  align-items: center;

  @include breakpoint(small down) {
    gap: rem-calc(6);
  }
}

.defaultPriceDescription {
  text-decoration: line-through;
  text-decoration-color: $c-red;
}

.savingsDescription {
  color: getColor($c-text-success);
  background-color: getColor($c-fill-success);
  padding: rem-calc(0 6);
  border-radius: 12px;

  &:global(#{$accent}) {
    color: getColor($c-text-bold);
    background-color: getColor($c-fill-success-bold);
  }
}

.subscriptionPlanMainInfo {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: rem-calc(4);
}

.subscriptionPlanName {
  color: getColor($c-text-default);

  &:global(#{$accent}) {
    color: getColor($c-text-bold);
  }
}

.subscriptionPlanPrice {
  color: getColor($c-text-default);

  &:global(#{$accent}) {
    color: getColor($c-text-bold);
  }
}

.subscriptionPlanDescription {
  color: getColor($c-text-tertiary);
  text-align: start;
}

.perMonth {
  color: getColor($c-text-tertiary);
}

.subscriptionPlanPriceBlock {
  display: flex;
  margin-left: auto;
  flex-direction: column;
  align-items: flex-end;
}
