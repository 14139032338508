@import 'utils';

.sidebar {
  width: 100vw;
  padding: rem-calc(0 16 16);
  transform: translateY(100%);
  border-radius: rem-calc(16 16 0 0);

  height: 80vh;
  position: fixed;
  top: 20vh;
  z-index: 6;
}

.sidebarContent {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.title {
  text-align: center;
  margin-bottom: rem-calc(24);
}

.sidebarOverlay {
  z-index: 6;

  @include breakpoint(small down) {
    display: block;
  }
}

.handleWrapper {
  position: sticky;
  top: 0;
  height: var(#{$responsive-modal-handle-height});
  display: flex;
  justify-content: center;
  z-index: 10;
}

.handle {
  margin-top: rem-calc(8);
  border-radius: 100px;
  width: 40px;
  height: 5px;
  background-color: getColor($c-scrollbar);
  opacity: 0.5;
}

.sidebarTitle {
  display: flex;
  align-items: center;
  justify-content: center;
}

.sidebarTitleSticky {
  position: sticky;
  top: 40px;
  z-index: 10;
}
