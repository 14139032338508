@import './utils';

.content {
  @extend %box-shadow;

  display: flex;

  position: absolute;
  top: calc(100% + 10px);
  left: rem-calc(-25);

  padding: rem-calc(0 18);

  flex-direction: column;
  justify-content: center;

  color: getColor($c-text-default);
  background-color: getColor($c-bg-neutral);
  opacity: 0;
  border-radius: 4px;

  visibility: hidden;
  transform: translateY(-10px);
  transition:
    transform $duration $easing,
    opacity $duration $easing,
    visibility 0s $duration;

  @include dropdown-opened {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
    transition-delay: 0s;
  }

  @include breakpoint(medium) {
    width: rem-calc(256);
  }
}

.textAndArrowContainer {
  @extend %text-truncate;

  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: rem-calc(4);

  color: getColor($c-text-default);
  border-radius: 4px;
  text-align: center;

  transition: background-color $duration $easing;

  &:hover {
    background-color: getColor($c-fill-hovered);
  }

  @include breakpoint(medium down) {
    justify-content: space-between;
  }
}

.trigger {
  @extend %button-reset;

  padding: rem-calc(4);

  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: rem-calc(16);

  cursor: pointer;
  white-space: nowrap;

  transition: color $duration $easing;

  &.fullWidth {
    width: 100%;
  }

  &.large {
    padding: rem-calc(8 16);

    @include breakpoint(medium down) {
      justify-content: flex-start;
    }
  }

  @include breakpoint(medium down) {
    margin-bottom: 0;
  }
}

.triggerText {
  @include elipsis();

  margin-right: rem-calc(4);
}

