@import 'utils';

.wrapper {
  display: flex;
  justify-content: space-evenly;
  align-items: flex-start;
  gap: rem-calc(8 4);
  padding: rem-calc(16 8 8);
  background-color: getColor($c-bg-neutral);
  border-radius: rem-calc(0 0 12 12);

  @include breakpoint(medium up) {
    overflow: visible;
  }

  @include breakpoint(xlarge down) {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    justify-items: center;
  }

  @include breakpoint(small down) {
    grid-template-columns: repeat(4, 1fr);
  }

  &--safari-fix {
    gap: rem-calc(16);
    padding-bottom: rem-calc(16);
    padding-inline: rem-calc(16);
  }
}

.motivationText {
  text-align: center;
}

.achievementItem {
  $achievementItemsXXLarge: 5;
  $achievementItemsSmall: 8;

  cursor: pointer;

  @include breakpoint(xxlarge up) {
    &:nth-child(n+#{$achievementItemsXXLarge + 1}) {
      display: none;
    }
  }

  @include breakpoint(small down) {
    &:nth-child(n+#{$achievementItemsSmall + 1}) {
      display: none;
    }
  }
}
