@import "utils";

.title {
  text-align: center;
  color: getColor($c-text-default);

  @include breakpoint(small down) {
    margin-bottom: rem-calc(24);
  }
}

.sidebarPaywallOverlay {
  z-index: 6;

  @include breakpoint(small down) {
    display: block;
  }
}

.handleWrapper {
  position: sticky;
  top: 8px;
  margin-top: 8px;
  margin-bottom: 24px;
  display: flex;
  justify-content: center;
  z-index: 1;
}

.handle {
  border-radius: 100px;
  width: 40px;
  height: 5px;
  background-color: $c-gray-40;
  opacity: 0.5;
}

.paywallModal {
  min-width: 900px;

  @include breakpoint(medium down) {
    min-width: 100%;
    min-height: 70vh;
    border-radius: 0;
    transform: translateX(0);
    bottom: 0;
  }
}

.sidebarPaywall {
  width: 100vw;
}
