@import 'utils';

.wrapper {
  display: flex;
  align-items: center;
  gap: rem-calc(4);
}

.videoPath {
  @include elipsis;
  @include text-platform-secondary;

  color: getColor($c-text-tertiary);
}

.icon {
  color: getColor($c-icon);
}

.iconLock {
  color: getColor($c-icon-disabled);
}
