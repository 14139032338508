@import 'utils';

.title {
  color: getColor($c-text-tertiary);
  margin-bottom: rem-calc(16);
}

.form {
  width: 145px;
  input {
    height: 1rem;
    @include text-platform-secondary;
  }
}

.wrapper {
  border-bottom: 1px solid getColor($c-border);
  margin-bottom: rem-calc(16);
  padding-bottom: rem-calc(16);
}

.info {
  display: flex;
  align-items: center;
  color: $c-gray-40;
  margin-left: rem-calc(40);
}

.red {
  color: $c-red;
}

.row {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
}

.recentTrackedTime {
  display: flex;
  width: 100%;
  align-items: center;
}

.timerIcon {
  width: 32px;
  height: 32px;
  padding: 0 8px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;

  background-color: getColor($c-bg-warning-secondary);
  color: getColor($c-icon-warning);
}
