@import 'utils';

.contentWrapper {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: rem-calc(4);
  justify-content: center;
  word-break: break-word;
}

.confettiBackground {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.iconWrapper {
  min-width: 77px;
  width: 77px;

  @include breakpoint(small down) {
    min-width: 40px;
    width: 40px;
  }
}

.streakFireIcon {
  width: 77px;
  height: auto;
  filter: drop-shadow(6px 6px 6px rgba(234, 81, 49, 0.4));

  @include breakpoint(small down) {
    width: 40px;
  }
}

.heading {
  color: getColor($c-text-default);
}

.description {
  color: getColor($c-text-tertiary);
}
