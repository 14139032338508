@import "utils";

.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.daysHint {
  color: getColor($c-text-tertiary);
  font-weight: $font-weight_medium;
}

.daysAmount {
  --icon-size: #{rem-calc(20)};

  display: flex;
  align-items: center;
  gap: rem-calc(4);

  color: getColor($c-text-secondary);
}
