@import 'utils';

.itemWrapper {
  position: relative;
}

.button {
  @extend %button-reset;

  width: 100%;
  padding: rem-calc(0 16);

  transition: background-color $duration $easing;

  &.disabled {
    cursor: not-allowed;

    .text {
      color: getColor($c-text-disabled);
    }
  }

  &:hover {
    background-color: getColor($c-fill-hovered);
  }
}

.content {
  display: flex;
  align-items: center;
  gap: rem-calc(12);

  height: rem-calc(48);

  border-bottom: 1px solid getColor($c-border);
}

.withoutBorder {
  border-bottom: none;
}

.text {
  @include text-platform-secondary;
  @include elipsis;

  color: getColor($c-text-secondary);
}

.icon {
  color: getColor($c-icon-secondary);
}

.highlighted {
  color: getColor($c-text-default);
  font-weight: 800;
}
