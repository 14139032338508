@import "utils";

.editable {
  @include text-platform-main;

  color: currentColor;
  word-wrap: break-word;

  details {
    display: block;
  }

  summary {
    display: list-item;
  }

  details summary {
    cursor: pointer;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-bottom: 16px;
    margin-top: 24px;
    font-weight: $font-weight_extra-bold;
  }

  h1 {
    @include text-platform-h1;

    margin: rem-calc(16 0);
  }

  h2 {
    @include text-platform-h2;
  }

  h1,
  h2 {
    padding-bottom: .3em;
    border-bottom: 1px solid getColor($c-border);
  }

  h3 {
    @include text-platform-h3;
  }

  h4 {
    @include text-platform-h3;
  }

  h5 {
    @include text-platform-h5;
  }

  h6 {
    font-size: .85em;
  }

  img {
    border-style: none;
  }

  code,
  kbd,
  pre {
    font-family: monospace,monospace;
    font-size: 1em;
  }

  p {
    margin-bottom: 10px;
    margin-top: 0;
    color: getColor($c-text-secondary)
  }

  strong, b {
    font-weight: $font-weight_extra-bold;
  }

  em {
    font-style: italic;
  }

  a {
    background-color: transparent;

    font-weight: $font-weight_extra-bold;
    color: getColor($c-text-info);
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  hr {
    padding: 0;
    margin: 24px 0;
    height: .25em;

    background-color: getColor($c-border);
    border: 0;

    &::before {
      display: table;
      content: "";
    }

    &::after {
      display: table;
      content: "";
      clear: both;
    }
  }

  table {
    display: block;
    width: 100%;

    border-collapse: collapse;
    border-spacing: 0;
    overflow: auto;
  }

  td,
  th {
    padding: 0;
  }

  table th {
    font-weight: $font-weight_extra-bold;
  }

  table td,
  table th {
    padding: 6px 13px;
    border: 1px solid getColor($c-border);
  }

  table tr {
    border-top: 1px solid getColor($c-border);
    background-color: getColor($c-bg-neutral);
  }

  table tr:nth-child(2n + 1) {
    background-color: getColor($c-bg-neutral-secondary);
  }

  table thead tr {
    background-color: getColor($c-bg-neutral);
  }

  blockquote {
    margin: 0;
  }

  ol,
  ul {
    margin-bottom: 0;
    margin-top: 0;
    padding-left: 0;
  }

  ol {
    list-style: decimal;
  }

  ul {
    position: relative;
    list-style: none;
  }


  /* Second-level ordered lists */
  ol ol,
  ul ol {
    list-style-type: lower-alpha;
  }

  /* Third-level ordered lists */
  ol ol ol,
  ol ul ol,
  ul ol ol,
  ul ul ol {
    list-style-type: lower-roman;
  }

  /* First-level unordered lists */
  ul {
    > li::before {
      position: absolute;
      display: inline-block;
      margin-left: -0.8em;
      content: "•";

      font-weight: bold;
      font-size: 2em;
      color: getColor($c-bg-neutral-quaternary);
    }
  }

  /* Second-level unordered lists */
  ul ul {
    > li::before {
      content: "◦";
      font-size: 1.4em;
    }
  }

  /* Third-level unordered lists */
  ul ul ul {
    > li::before {
      content: "▪";
      font-size: 1.2em;
    }
  }

  ol,
  ul {
    padding-left: 2em;
  }

  ol ol,
  ol ul,
  ul ol,
  ul ul {
    margin-bottom: 0;
    margin-top: 0;
  }

  li > p {
    margin-top: 16px;
  }

  li + li {
    margin-top: .25em;
  }

  dl {
    padding: 0;
  }

  dl dt {
    padding: 0;
    margin-top: 16px;

    font-size: 1em;
    font-style: italic;
    font-weight: 600;
  }

  dl dd {
    margin-bottom: 16px;
    padding: 0 16px;
  }


  dd {
    margin-left: 0;
  }

  code,
  pre {
    font-family: $f-monospace;
    font-size: 12px;
  }

  pre {
    margin-bottom: 0;
    margin-top: 0;
  }

  :first-child {
    margin-top: 0!important;
  }

  :last-child {
    margin-bottom: 0!important;
  }

  a:not([href]) {
    color: inherit;
    text-decoration: none;
  }

  blockquote,
  dl,
  ol,
  p,
  pre,
  table,
  ul {
    margin-bottom: 16px;
    margin-top: 0;
  }

  blockquote {
    padding: 0 1em;
    border-left: .25em solid getColor($c-bg-neutral-quaternary);
    color: getColor($c-text-default);
  }

  blockquote > :first-child {
    margin-top: 0;
  }

  blockquote > :last-child {
    margin-bottom: 0;
  }

  kbd {
    display: inline-block;
    padding: 3px 5px;

    border: 1px solid getColor($c-border);
    border-radius: 3px;
    background-color: getColor($c-bg-neutral-secondary);
    box-shadow: inset 0 -1px 0 getColor($c-border-transparent);

    font-size: 11px;
    color: getColor($c-text-default);
    line-height: 10px;
    vertical-align: middle;
  }

  img {
    box-sizing: content-box;
    max-width: 100%;
    background-color: getColor($c-bg-neutral);
  }

  img[align=right] {
    padding-left: 20px;
  }

  img[align=left] {
    padding-right: 20px;
  }

  code {
    margin: 0;
    padding: .2em .4em;

    border-radius: 3px;
    background-color: getColor($c-bg-neutral-quaternary);

    font-size: 85%;
  }

  pre {
    word-wrap: normal;
  }

  pre > code {
    margin: 0;
    padding: 0;

    border: 0;
    background: transparent;

    font-size: 100%;
    white-space: pre;
    word-break: normal;
  }

  :global(.highlight) {
    margin-bottom: rem-calc(16);
  }

  :global(.highlight) pre {
    margin-bottom: 0;
    word-break: normal;
  }

  :global(.highlight) pre,
  pre {
    padding: rem-calc(4);

    border: 1px solid getColor($c-border);
    border-radius: 4px;
    background-color: getColor($c-bg-neutral);
    overflow: auto;

    font-size: 85%;
    line-height: 1.45;
  }

  pre code {
    display: inline;
    margin: 0;
    padding: 0;
    max-width: 100%;

    border: 0;
    background-color: transparent;
    overflow: visible;

    word-wrap: normal;
    line-height: inherit;
  }

  :global(.removed) {
    background-color: getColor($c-bg-danger-secondary);
    text-decoration: line-through;

    & > pre {
      background-color: getColor($c-bg-danger-secondary);
    }

    & > code {
      background-color: getColor($c-bg-danger-secondary);
    }
  }

  :global(.added) {
    background-color: getColor($c-bg-success-secondary);

    & > pre {
      background-color: getColor($c-bg-success-secondary);
    }

    & > code {
      background-color: getColor($c-bg-success-secondary);
    }
  }
}
