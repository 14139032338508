@import "utils";

.iconWrapper {
  position: relative;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;

  border: 1px solid transparent;

  @include icon-styles {
    @include square(rem-calc(20));
  }
}

.iconGreen {
  color: getColor($c-text-success);
  background-color: getColor($c-bg-success-secondary);
  border-color: getColor($c-border-success);
}

.iconYellow {
  color: getColor($c-text-warning);
  background-color: getColor($c-bg-warning-secondary);
  border-color: getColor($c-border-warning);
}

.iconRed {
  color: getColor($c-text-brand);
  background-color: getColor($c-bg-brand-secondary);
  border-color: getColor($c-border-brand);
}

.iconBlue {
  color: getColor($c-text-info);
  background-color: getColor($c-bg-info-secondary);
  border-color: getColor($c-border-info);
}

.iconPurple {
  color: getColor($c-text-accent-purple);
  background-color: getColor($c-bg-accent-purple-secondary);
  border-color: getColor($c-border-accent-purple);
}

.iconGray {
  color: getColor($c-text-default);
  background-color: getColor($c-bg-neutral-secondary);
  border-color: getColor($c-border)
}

.iconDarkGray {
  color: getColor($c-bg-neutral-quaternary);
  background-color: getColor($c-bg-neutral-secondary);
  border-color: getColor($c-border)
}

.iconPink {
  color: getColor($c-fill-accent-magenta-bold);
  background-color: getColor($c-bg-accent-magenta-secondary);
  border-color: getColor($c-border-accent-magenta)
}

.iconDanger {
  color: getColor($c-text-danger);
  background-color: getColor($c-bg-danger-secondary);
  border-color: getColor($c-border-danger);
}

.iconSizeSmall {
  @include square(rem-calc(20));

  :global(#{$emoji}),
  :global(#{$icon}) {
    @include square(rem-calc(12));
  }
}

.iconSizeMedium {
  @include square(rem-calc(48));

  :global(#{$emoji}),
  :global(#{$icon}) {
    @include square(rem-calc(24));
  }
}

.iconSizeXXLarge {
  @include square(rem-calc(104));

  :global(#{$emoji}),
  :global(#{$icon}) {
    @include square(rem-calc(32));
  }
}
