@import "utils";

.container {
  background-color: var(--background, #{$c-gray-20});
  border-radius: 50%;
  width: 100%;
  height: 100%;
  display: flex;
  overflow: hidden;

  svg {
    width: 100%;
    height: 100%;
  }
}
