@import "utils";

.fireDay {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.fireIcon {
  @include square(30)
}

.fireText {
  @include text-platform-secondary;

  color: getColor($c-text-secondary);
}

.fireTextWrapper {
  padding: rem-calc(8);
}
