@import 'utils';

.avatarWrapper {
  display: flex;
  margin-top: rem-calc(32);

  &::before {
    content: '';
    width: calc(100% + 64px);
    height: 80px;
    background-color: getColor($c-bg-neutral-tertiary);
    position: absolute;
    top: 0;
    left: -32px;
    z-index: -1;
  }
}

.avatar {
  position: relative;
  border-radius: 50%;
  border: 6px solid $c-white
}
