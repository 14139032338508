@import "utils";

.emailLabel {
  @include text-platform-secondary;
  font-weight: 800;
}

.emailField {
  & :first-child {
    font-weight: 400;
    margin-bottom: 0;
  }

  :global(.icon) {
    display: none;
  }
}

.total {
  display: flex;
  justify-content: space-between;
  margin: rem-calc(40 0 16);
}

.cancelAnytime {
  margin: rem-calc(0 auto 6);
  color: getColor($c-text-secondary);
}

.cardElement {
  padding: rem-calc(16);
  border: 1px solid getColor($c-border);
  border-radius: rem-calc(16);
}

.cardElementInvalid {
  border-color: getColor($c-text-danger);
}

.cardInputValidationError {
  margin: rem-calc(8 16);
  color: getColor($c-text-danger);

  :global(.is-accent) {
    font-weight: $font-weight_extra-bold;
  }
}

.terms {
  @include text-platform-small;
  color: getColor($c-text-tertiary);
  text-align: center;

  margin-bottom: rem-calc(8);

  :global(.link) {
    text-decoration: underline;
  }
}

.submitButtonContainer {
  margin-top: rem-calc(12);
  position: sticky;
  bottom: 16px;
}

.submitButton {
  width: 100%;
}

.checkout {
  position: relative;
  display: flex;
  flex-direction: column;
}

.submitSection {
  width: 100%;
  position: sticky;
  bottom: 0;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, getColor($c-bg-neutral) 100%);
  padding: rem-calc(12 0);
}

.submitSectionPortal {
  position: relative;
  background: getColor($c-bg-transparent);
  padding: 0;
}

.paymentElement {
  width: 100%;
}
